// Generated by Framer (58a021c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {BqiuBBSuU: {hover: true}, HAVYta_Cw: {hover: true}, KrUyzpOz2: {hover: true}};

const cycleOrder = ["BqiuBBSuU", "HAVYta_Cw", "KrUyzpOz2"];

const variantClassNames = {BqiuBBSuU: "framer-v-1rd425b", HAVYta_Cw: "framer-v-16sxw3p", KrUyzpOz2: "framer-v-iiakr5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Facebook: "BqiuBBSuU", Instagram: "HAVYta_Cw", LinkedIn: "KrUyzpOz2"};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; background?: string; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "BqiuBBSuU", background: wtA7o7rpL = "rgb(255, 255, 255)", link: bD3fRXosl, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BqiuBBSuU", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-nUd2O", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={bD3fRXosl} openInNewTab><motion.a {...restProps} className={`${cx("framer-1rd425b", className)} framer-a5k371`} data-framer-name={"Facebook"} layoutDependency={layoutDependency} layoutId={"BqiuBBSuU"} ref={ref} style={{opacity: 1, ...style}} variants={{"HAVYta_Cw-hover": {opacity: 0.8}, "KrUyzpOz2-hover": {opacity: 0.8}}} {...addPropertyOverrides({"BqiuBBSuU-hover": {"data-framer-name": undefined}, "HAVYta_Cw-hover": {"data-framer-name": undefined}, "KrUyzpOz2-hover": {"data-framer-name": undefined}, HAVYta_Cw: {"data-framer-name": "Instagram"}, KrUyzpOz2: {"data-framer-name": "LinkedIn"}}, baseVariant, gestureVariant)}><motion.div className={"framer-ljrchh-container"} layoutDependency={layoutDependency} layoutId={"aBJcJoqwV-container"} style={{opacity: 1}} variants={{"BqiuBBSuU-hover": {opacity: 0.8}}}><Material color={wtA7o7rpL} height={"100%"} iconSearch={"Home"} iconSelection={"Facebook"} iconStyle15={"Sharp"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"aBJcJoqwV"} layoutId={"aBJcJoqwV"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({HAVYta_Cw: {iconSelection: "Instagram"}, KrUyzpOz2: {iconSelection: "LinkedIn"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-nUd2O [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nUd2O .framer-a5k371 { display: block; }", ".framer-nUd2O .framer-1rd425b { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 35px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 35px; }", ".framer-nUd2O .framer-ljrchh-container { flex: 1 0 0px; height: 100%; position: relative; width: 1px; }", ".framer-nUd2O .framer-v-1rd425b .framer-1rd425b, .framer-nUd2O .framer-v-16sxw3p .framer-1rd425b, .framer-nUd2O .framer-v-iiakr5 .framer-1rd425b { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nUd2O .framer-1rd425b { gap: 0px; } .framer-nUd2O .framer-1rd425b > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-nUd2O .framer-1rd425b > :first-child { margin-left: 0px; } .framer-nUd2O .framer-1rd425b > :last-child { margin-right: 0px; } }", ".framer-nUd2O.framer-v-16sxw3p .framer-1rd425b, .framer-nUd2O.framer-v-iiakr5 .framer-1rd425b, .framer-nUd2O.framer-v-1rd425b.hover .framer-1rd425b { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 35px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 35
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HAVYta_Cw":{"layout":["fixed","fixed"]},"KrUyzpOz2":{"layout":["fixed","fixed"]},"hPLt5qhCU":{"layout":["fixed","fixed"]},"iTCf4VP5y":{"layout":["fixed","fixed"]},"DhrsxDLY4":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wtA7o7rpL":"background","bD3fRXosl":"link"}
 */
const FramerR3Ba_MRFH: React.ComponentType<Props> = withCSS(Component, css, "framer-nUd2O") as typeof Component;
export default FramerR3Ba_MRFH;

FramerR3Ba_MRFH.displayName = "Social Icons";

FramerR3Ba_MRFH.defaultProps = {height: 35, width: 35};

addPropertyControls(FramerR3Ba_MRFH, {variant: {options: ["BqiuBBSuU", "HAVYta_Cw", "KrUyzpOz2"], optionTitles: ["Facebook", "Instagram", "LinkedIn"], title: "Variant", type: ControlType.Enum}, wtA7o7rpL: {defaultValue: "rgb(255, 255, 255)", title: "Background", type: ControlType.Color}, bD3fRXosl: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerR3Ba_MRFH, [...MaterialFonts])